import React from 'react';
import './Saved.css';
import { NavLink } from 'react-router-dom';
import BlogNavbar from './BlogNavbar';
import Blogtop from './Blogtop.js';
import Blogstory from './blogstory.jpg';
import TrendingStories from './TrendingStories.js';


const Saved = () => {
    return (<div className='SavedBlogpage'>
        <div className=''><BlogNavbar /></div>
        <div className=''><Blogtop  /></div>
        <div className='CardTrendingStory'><TrendingStories /></div>
        <div className='SavedBlog'>
            <div className='options-menu3 link white'>
                        <h3><NavLink className='link white' to="/Share">Share</NavLink></h3>
                        <h3><NavLink className='link white' to="/Read">Read</NavLink></h3>
                        <h3><NavLink className='link white' to="/Saved">Saved</NavLink></h3>

            </div>
            <div className='BlogSavedCard'>
                <div className='BlogSavedTitleCard'>
                    <img className='Blogsavedimg' alt="Blogstory" src={Blogstory} />
                    <div className='BlogTextSavedPart'>
                        <div className='BlogSavedTitle'>Title of the story</div>
                        <div className='BlogSavedtext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                        <div className='linksaved'><NavLink className='linkreadtext black hover-yellow' to="/Login2">Continue Reading...</NavLink></div>
                    </div>
                </div>
                <div className='BlogSavedTitleCard'>
                    <img className='Blogsavedimg' alt="Blogstory" src={Blogstory} />
                    <div className='BlogTextSavedPart'>
                        <div className='BlogSavedTitle'>Title of the story</div>
                        <div className='BlogSavedtext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                        <div className='linksaved'><NavLink className='linkreadtext black hover-yellow' to="/Login2">Continue Reading...</NavLink></div>
                    </div>
                </div>
                <div className='BlogSavedTitleCard'>
                    <img className='Blogsavedimg' alt="Blogstory" src={Blogstory} />
                    <div className='BlogTextSavedPart'>
                        <div className='BlogSavedTitle'>Title of the story</div>
                        <div className='BlogSavedtext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                        <div className='linksaved'><NavLink className='linkreadtext black hover-yellow' to="/Login2">Continue Reading...</NavLink></div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Saved;