import React from 'react';
import './Share.css';
import { NavLink } from 'react-router-dom';
import BlogNavbar from './BlogNavbar';
import Blogtop from './Blogtop.js';
import Plus from './plus.png';
import TrendingStories from './TrendingStories.js';


const Share = () => {
    return (<div className='ShareBlogpage'>
        <div className=''><BlogNavbar /></div>
        <div className=''><Blogtop  /></div>
        <div className='CardTrendingStory'><TrendingStories /></div>
        <div className='ShareBlog'>  
                <div className='options-menu1 link white'>
                    <h3><NavLink className='link white' to="/Share">Share</NavLink></h3>
                    <h3><NavLink className='link white' to="/Read">Read</NavLink></h3>
                    <h3><NavLink className='link white' to="/Saved">Saved</NavLink></h3>

                </div>              
                <div className='card'>
                    <textarea className='sharebox' type='text' placeholder='share your experience.............' />
                    <div className='shareBottomBar'>
                        <img className='' alt="more" src={Plus} />
                        <button className='input-reset   white br4 grow pointer '><NavLink className='link white grow' to="/Loggedinhomepage">Post</NavLink></button>
                        
                    </div>
                </div>
            
        </div>
    </div>)
}

export default Share;