import React from 'react';
import { NavLink } from 'react-router-dom';
import './Register.css';
import RegisterForm from './RegisterForm';
import Image from './Register.jpeg'


const Register = () => {
	return(<div className='register_page'> 
	<img className='bg1' src={Image} alt='Background'/>
	<div className='tc form_register'><RegisterForm/></div>
	<div className='white pt0 vaguss_text2'><NavLink className=' link white z-3' to="/">Vaguss</NavLink></div>
	<div className='slogan_text2 white hover-light-green'>Join Our Community</div>
	<div className='overlay'></div>	
	 

	 </div>)
}
export default Register;

