import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Loginvid from './Login/loginvid';
import Register from './Register/Register';
import Dabout from './Dashboard/About/Dabout.js';
import Dexplore from './Dashboard/Explore/Dexplore.js';
import Write from './Dashboard/Contact/write.js';
import Loggedinhomepage from './loggedin/Loggedinhomepage';
import Share from './loggedin/Blog/Share';
import Read from './loggedin/Blog/Read';
import Saved from './loggedin/Blog/Saved';
import reportWebVitals from './reportWebVitals';
import 'tachyons';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


ReactDOM.render(
   <Router>
      <Routes>
      <Route path="/" element={<App />} />
      <Route path="/Login2" element={<Loginvid />} /> 
      <Route path="/Register" element={<Register />} />
      <Route path="/Dabout" element={<Dabout />} />
      <Route path="/Dexplore" element={<Dexplore />} />
      <Route path="/Write" element={<Write />} />
      <Route path='/Loggedinhomepage' element={<Loggedinhomepage />} />
      <Route path='/Share' element={<Share />} /> 
      <Route path='/Read' element={<Read />} /> 
      <Route path='/Saved' element={<Saved />} /> 
      
      </Routes>
    </Router>
    
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
