import React from 'react';
import './App.css';
import Dashboard from './Dashboard/Dashboard';



const App = () => 
{
  return(
    <div className=''>
     <Dashboard />
        
    </div>)
}
export default App;