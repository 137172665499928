import React from 'react';
import Navbar from './Navbar/Navbar';
import NavbarMobile from './Navbar/NavbarMobile';
import Hbg1 from './hbg1.jpg';
import './Dashboard.css';
import { NavLink } from 'react-router-dom';
import Dblog from './Blog/dblog.js';
import Dabout from './About/Dabout.js';
import Dexplore from './Explore/Dexplore.js';
import Write from './Contact/write.js';
import Footer from './Footer/Footer.js';
const Dashboard = () =>
{
return(<div>
      <div className='tc nav_bigscreen'><Navbar /></div>
      <div className='nav_smallscreen'><NavbarMobile /></div>
      <div className='absolute white z-4 dptext'>
      	<h1 className='dptext1 white z-4'>Exploring</h1>
      	<h1 className='dptext2 white z-4'>Has Became Easy</h1>
      	<h5 className='dptext3 white z-4'>with VAGUSS</h5>
  		<button className='started1btnold white'><NavLink className='link white grow' to="/Login2">GET STARTED</NavLink></button>
      </div>
      <div className='overlay0 z-2'></div>
      <div><img className="dp z-1" src={Hbg1} alt="First slide"/></div>
      <div className=''><Dblog /></div>
      <div className='tc'><Dexplore /></div>
      <div className='tc'><Dabout /></div>
      <div className='dwrite tc'><Write /></div>
      <div className=''><Footer /></div>
      
	</div>)
}
export default Dashboard;