import React from 'react';
import { fallDown as Menu } from 'react-burger-menu';
import './NavbarMobile.css';

class NavbarMobile extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }


  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu>
        <a id="home" className="menu-item" href="/Dabout">About Us</a>
        <a id="about" className="menu-item" href="/Dblog">Blog</a>
        <a id="contact" className="menu-item" href="/Write">Contact</a>
        
      </Menu>
    );
  }
}


export default NavbarMobile;