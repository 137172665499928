import React from 'react';
import './write.css';


// var ReactFitText = require('react-fittext');

const write =()=>{
	return (<div className='write'>
		<meta charset="utf-8"/>
		<meta name="viewport" content="height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1"/>
		<meta name="description" content=""/>
		<div className='writebac'>
			<div className='writeus1 white'>Write Us</div>
			<div className='zone'>
				<div className='writeleft'>
					<div className='dataproblem w-100'>
						<div className="mt0">
						        <label className="db lh-copy black" for="name">Your Full Name</label>
						        <input className="box input-reset bg-transparent bg-white black w-80" type="text" name="name"  id="name"/>
				     	</div>
				     	<div className="mt0">
						        <label className="db lh-copy black" for="email-address">Active mail address</label>
						        <input className="box input-reset bg-transparent bg-white black w-80" type="email" name="email-address"  id="email-address"/>
				     	</div>
				     	<div className="mt0">
						        <label className="db lh-copy black" for="email-address">Phone Number</label>
						        <input className="box input-reset bg-transparent bg-white black w-80" type="text" name="phone-number"  id="phone-number"/>
				     	</div>
				     </div>
			     	<div className='mailbox'><label className="pa2 ma2 lh-copy f5 pointer b"><input className='ma2 b f5' type="checkbox"/>Subscribe for mailbox</label></div>
				</div>
				<div className='writeright'>
					<div className='prblm'><input className='problem bg-white tc' type='text' placeholder='Describe your problem' /></div>
					<div className='mailbox1'><label className="lh-copy pointer black"><input className='mr2 f5' type="checkbox"/>Subscribe for mailbox</label></div>
				</div>
			</div>
			<div className='submitbtn'>
				<button className='blue submitbutton tc b'>Submit</button>
			</div>

		</div>

		</div>)
}
export default write;