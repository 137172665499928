import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {FaBars, FaTimes} from 'react-icons/fa'
import './BlogNavbar.css'

const BlogNavbar = () => {
    const[click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const [color, setColor] = useState(false)
        const changeColor =() => {
            if(window.scrollY >= 100) {
                setColor(true)
            } else {
                setColor(false)
            }
        }

        window.addEventListener('scroll', changeColor)

    return (
        <div className={color ? 'header header-bg' : 'header'}>
            
           <div className={click ? 'nav-menu active' : 'nav-menu'}>
               <div className=''>
                   <Link to='/Share'>Share</Link>
               </div>
               <div className=''>
                   <Link to='/Read'>Read</Link>
               </div>
               <div className=''>
                   <Link to='/Saved'>Saved</Link>
               </div>
           </div>
           <div className='hamburger' onClick={handleClick}>
            {click ? (<FaTimes size={20} style={{color: '#fff'}} />) : (<FaBars size={20} style={{color: '#fff'}} />)}
               
           </div>
        </div>
    )
}

export default BlogNavbar;