import React from 'react';
import './SigninForm.css';
import { NavLink } from 'react-router-dom';
import Flogo from './flogo.png';
import Twitter from './twitter.png'
import Loggedinhomepage from '../loggedin/Loggedinhomepage.js'
const SigninForm = () => {

return(
<div className='relative z-3 form_container white bg-transparent br4 bw2'> 
	<h1 className='white  z-2 signin'> Sign In </h1>
	<h3 className='white z-2 db newuser'>New User ?<NavLink className='caab home link b yellow pv0 pl0 ml2' to="/Register">Create an account</NavLink></h3>
{/*		<main className='pa0 mt0'>*/}
		<div className="">
		   	{/* <fieldset id="sign_up" className="ba b--transparent ph0 mh0 ">*/}
		      		{/*<legend className="f4 fw6 ph6 mh0">Sign In</legend>*/}
				     	<div className="mt3">
					       <label className="db lh-copy f6 pa1" for="email-address">Email</label>
					        <input className="box pa1 input-reset bg-transparent white" type="email" name="email-address"  id="email-address"/>
			     	 	</div>
			      		<div className="mv3">
					        <label className="db lh-copy f6" for="password">Password</label>
					        <input className="box pa1  bg-transparent white" type="password" name="password"  id="password"/>
			      		</div>
		      		<label className="pa2 ma0 lh-copy f6 pointer"><input type="checkbox"/> Remember me</label>

		    		<div className="">
		      			
		      			<button className='signbox ph4 pv2  input-reset   white br4 grow pointer f6 dib mt3'><NavLink className='link white grow' to="/Loggedinhomepage">Sign in</NavLink></button>
		    		</div>
		    		<div className="lh-copy mt3">
		      			<a href="#0" className="f6 link dim white db ma2">Forgot your password?</a>
		      		</div>
		      		<div className='v-mid'>
		      			<p className='or grey'>OR</p>
		      			<button className='Google bg-white black br4 ma2 v-mid'>
		      				<img className='Glogo v-mid' alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
		      					<p className='logotext di'>Continue With Google</p>
		      			</button>
		      			<button className='Facebook white br4 ma2 v-mid'>
		      				<img className='Flogo v-mid' alt="Facebook sign-in" src={Flogo} />
		      					<p className='flogotext di'>Continue With Facebook</p>
		      			</button>
		      			<button className='Twitter white br4 ma2 v-mid'>
		      				<img className='tlogo v-mid' alt="Twitter sign-in" src={Twitter} />
		      					<p className='flogotext di'>Continue With Twitter</p>
		      			</button>
		      		</div>
		</div>

</div>)
}

export default SigninForm





