import React from 'react';
import './Dexplore.css';
import Searchi from './searchi.png';
import Picnic from './picnic.jpg';
import Road from './road.jpg';
import Topspot from './topspot.jpg';
import Busyplace from './busyplace.jpg';
import { NavLink } from 'react-router-dom';



const Dexplore =()=>{
	return (<div className='dexplore'>
		<div className='expsearch tc'>
			<input className='searchbarexplore tc' type='text' placeholder='Select Location...' /><img className='searchlogoexplore link' alt='search' src={Searchi} />
		</div>
		<p className='result white tc'>showing results for the 'LOCATION'</p>
		<div className='dexploreimg'>
			<div className='spots grow'><img className='dexplorespots' src={Picnic} alt='picnic spots'/>
				<div className='spotsoverlay'></div><h3 className='white spotsoverlaytext'>Picnic Spots</h3>
			</div>
			<div className='spots grow'><img className='dexplorespots' src={Road} alt='Road' />
				<div className='spotsoverlay'></div><h3 className='white spotsoverlaytext'>Awesome Roads</h3>
			</div>
			<div className='spots grow'><img className='dexplorespots' src={Topspot} alt='Top spots' />
				<div className='spotsoverlay'></div><h3 className='white spotsoverlaytext'>Top Spots</h3>
			</div>
			<div className='spots grow'><img className='dexplorespots' src={Busyplace} alt='Busy place' />
				<div className='spotsoverlay'></div><h3 className='white spotsoverlaytext'>Busy Places</h3>
			</div>
		</div>


		
		<div className='more'>
			<NavLink className='link white moret hover-yellow' to="/Login2">show more >>>> </NavLink>
		</div>

		</div>)
}
export default Dexplore;
