import React from 'react';
import { NavLink } from 'react-router-dom';
import './Loggedinhomepage.css';
import Share from './Blog/Share.js';
import Loggedinhome from './loggedinhome.jpg';
import Navbar from '../Dashboard/Navbar/Navbar.js';
import NavbarMobile from '../Dashboard/Navbar/NavbarMobile.js';


const Loggedinhomepage = () => {

return(
<div className='loggedin_home_mainpage'>
	<div className='tc nav_bigscreen'><Navbar /></div>
    <div className='nav_smallscreen'><NavbarMobile /></div>
	<div className=''><img className="loggedinhomeimg" src={Loggedinhome} alt="First slide"/></div>
	<div className='loggedinhomeoverlaycard'></div>
	<div className='absolute white z-4 loggedinhomedptext'>
      	<h1 className='loggedinhomedptext1 white z-4'>Exploring</h1>
      	<h1 className='loggedinhomedptext2 white z-4'>Has Became Easy</h1>
      	<h5 className='loggedinhomedptext3 white z-4'>with VAGUSS</h5>
  		<button className='loggedinhomestarted white'><NavLink className='loggedinhomelink white grow' to="/Share">Explore</NavLink></button>
    </div>
	<div className='loggedin_blog_home_clickbtn'><button className='loggedin_blog_homepage input-reset white br4 grow pointer f6 dib mt3'><NavLink className='link white grow' to='/Share'>Blog</NavLink></button></div>




</div>)



}
export default Loggedinhomepage;