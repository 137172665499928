import React from 'react';
import './TrendingStories.css';
import Blogstory from './blogstory.jpg';

const TrendingStories = () => {
	return(<div className='TrendingStoriesCardSide'>
		<div className='TrendingStoriesMainText tc'>Trending Stories</div>
		<div className='AllBlogStoryCard'>
			<div className='BlogstoryCard'>
				<img className='Blogstoryimg' alt="Blogstory" src={Blogstory} />
				<div className='Blogstorytext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
			</div>

			<div className='BlogstoryCard'>
				<img className='Blogstoryimg' alt="Blogstory" src={Blogstory} />
				<div className='Blogstorytext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
			</div>

			<div className='BlogstoryCard'>
				<img className='Blogstoryimg' alt="Blogstory" src={Blogstory} />
				<div className='Blogstorytext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
			</div>

			<div className='BlogstoryCard'>
				<img className='Blogstoryimg' alt="Blogstory" src={Blogstory} />
				<div className='Blogstorytext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
			</div>

			<div className='BlogstoryCard'>
				<img className='Blogstoryimg' alt="Blogstory" src={Blogstory} />
				<div className='Blogstorytext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
			</div>

			<div className='BlogstoryCard'>
				<img className='Blogstoryimg' alt="Blogstory" src={Blogstory} />
				<div className='Blogstorytext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing.</div>
			</div>
			<div className='BlogstoryCard'>
				<img className='Blogstoryimg' alt="Blogstory" src={Blogstory} />
				<div className='Blogstorytext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing.</div>
			</div>
			<div className='BlogstoryCard'>
				<img className='Blogstoryimg' alt="Blogstory" src={Blogstory} />
				<div className='Blogstorytext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing.</div>
			</div>
			<div className='BlogstoryCard'>
				<img className='Blogstoryimg' alt="Blogstory" src={Blogstory} />
				<div className='Blogstorytext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing.</div>
			</div>
		</div>


	</div>)




}
export default TrendingStories;