import React from 'react';
import './dblog.css';
import { NavLink } from 'react-router-dom';
import Dblog1 from './dblog1.jpg';
import Dblog2 from './dblog2.jpg';
import Dblog3 from './dblog3.jpg';

const dblog =()=>{
	return (<div className='dblog'>
			<div className='left'>
				<div className='absolute z-4 dptext0'>
	      			<h1 className='dptext5 z-4'>Read & Share</h1>
	      			<h1 className='dptext6 z-4'>Your Experiences</h1>
	  				<button className='started1'><NavLink className='link grow start b' to="/Login2">Start Now</NavLink></button>
      			</div>
			</div>
			<div className='right'>
				<div className='dblogimg'>
					<div className='grow'>
						<div className='dblog3 z-2'><img className='shadowbox3' src={Dblog3} alt='dblog3' />
							<div className='odb3 z-3'></div>
						</div>
					</div>
					<div className='grow'>
						<div className='dblog2 z-3'><img className='shadowbox2' src={Dblog2} alt='dblog2' />
							<div className='odb2 z-4 shadowbox'></div>
						</div>
					</div>
					<div className='grow'>
						<div className='dblog1 z-4'><img className='shadowbox1' src={Dblog1} alt='dblog1' />
							<div className='odb1 z-5 shadowbox'></div>
						</div>
					</div>
					
				</div>
			</div>
			


		</div>)
}
export default dblog;