import React from 'react';
import './Read.css';
import { NavLink } from 'react-router-dom';
import BlogNavbar from './BlogNavbar';
import Blogtop from './Blogtop.js';
import Blogstory from './blogstory.jpg';
import TrendingStories from './TrendingStories.js';


const Read = () => {
    return (<div className='ReadBlogpage'>
        <div className=''><BlogNavbar /></div>
        <div className=''><Blogtop  /></div>
        <div className='CardTrendingStory'><TrendingStories /></div>
        <div className='ReadBlog'>
            <div className='options-menu2 link white'>
                        <h3><NavLink className='link white' to="/Share">Share</NavLink></h3>
                        <h3><NavLink className='link white' to="/Read">Read</NavLink></h3>
                        <h3><NavLink className='link white' to="/Saved">Saved</NavLink></h3>

            </div>
            <div className='BlogReadCard'>
                <div className='BlogReadTitleCard'>
                    <img className='Blogreadimg' alt="Blogstory" src={Blogstory} />
                    <div className='BlogTextPart'>
                        <div className='BlogTitle'>Title of the story</div>
                        <div className='Blogreadtext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                        <div className='linkread'><NavLink className='linkreadtext black hover-yellow' to="/Login2">Continue Reading...</NavLink></div>
                    </div>
                </div>
                <div className='BlogReadTitleCard'>
                    <img className='Blogreadimg' alt="Blogstory" src={Blogstory} />
                    <div className='BlogTextPart'>
                        <div className='BlogTitle'>Title of the story</div>
                        <div className='Blogreadtext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                        <div className='linkread'><NavLink className='linkreadtext black hover-yellow' to="/Login2">Continue Reading...</NavLink></div>
                    </div>
                </div>
                <div className='BlogReadTitleCard'>
                    <img className='Blogreadimg' alt="Blogstory" src={Blogstory} />
                    <div className='BlogTextPart'>
                        <div className='BlogTitle'>Title of the story</div>
                        <div className='Blogreadtext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas fringilla nisl finibus condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                        <div className='linkread'><NavLink className='linkreadtext black hover-yellow' to="/Login2">Continue Reading...</NavLink></div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Read;