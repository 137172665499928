import React from 'react';
import './RegisterForm.css';
import { NavLink } from 'react-router-dom';
const RegisterForm = () => {

return(
<div className='absolute  z-3 register_container tc black br4 pa3 bw2'> 
	<h1 className='black  z-2 signin'> Create New Account </h1>
	<div className='black z-2 db already_accnt tc'>Already Have An Account ?<NavLink className='homp home link blue pv0 pl2 ml0 mr0' to="/Login2">Sign In</NavLink></div>
	<main className='pa0 mt0'>
	  <form className="measure center">
	   	 <fieldset id="sign_up" className="ba b--transparent ph0 mh0 ">
	      		{/*<legend className="f4 fw6 ph6 mh0">Sign Up</legend>*/}
			     	<div className="mt3">
				        <label className="db lh-copy f6 pa1" for="name">Name</label>
				        <input className="box pa1 input-reset bg-transparent bg-white black w-80" type="text" name="name"  id="name"/>
		     	 	</div>
		     	 	<div className="mt3">
				        <label className="db lh-copy f6 pa1" for="username">Username</label>
				        <input className="box pa1 input-reset bg-transparent bg-white black w-80" type="text" name="username"  id="username"/>
		     	 	</div>
		     	 	<div className="mt3">
				        <label className="db lh-copy f6 pa1" for="email-address">Email</label>
				        <input className="box pa1 input-reset bg-transparent bg-white black w-80" type="email" name="email-address"  id="email-address"/>
		     	 	</div>
		     	 	<div className="mv3">
				        <label className="db lh-copy f6" for="password">Password</label>
				        <input className="box pa1  bg-transparent black w-80" type="password" name="password"  id="password"/>
		      		</div>
		      		<div className="mv3">
				        <label className="db lh-copy f6" for="confirm-password">Confirm Password</label>
				        <input className="box pa1  bg-transparent black w-80" type="password" name="confirm-password"  id="confirm-password"/>
		      		</div>
	      		
	  	</fieldset>
	    		<div className="">
	      			<input className="signbox ph4 pv2  input-reset   white br4 grow pointer f6 dib" type="submit" value="Register"/>
	    		</div>
	    		
	  </form>
	</main>
</div>)
}

export default RegisterForm
