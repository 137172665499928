import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import Searchi from './searchi.png';

const Navbar = () =>
{
return(<div>
      <div className='navbar1'>
      	<div className='searchbarlogo'> 
	      <div className='searchbartext'><input className='searchbar tc' type='text' placeholder='search...' /></div>
	      <div className='searchbarimg'><img className='searchlogo link' alt='search' src={Searchi} /></div>
	    </div>
	      <span className='logo1'><NavLink className='link white logo1' to="/">Vaguss</NavLink></span> 
	    <div className='navbarmenu link'>
	      <span className='hme1 menu link'>Home</span>
	      <span className='blog1 menu link'>Blog</span>
	      <span className='explore1 menu link'>Explore</span>
	      <span className='about1 menu link'>About Us</span>
	      <span className='contact1 menu link'>Contact Us</span>
	      <button className='signup1 menu'><NavLink className='link white' to="/Login2">Login | SignUp</NavLink></button>
	  	</div>
      </div>
	</div>)
}
export default Navbar;