import React from 'react';
import { NavLink } from 'react-router-dom';
import './Loginvid.css';
import Background from './login_bg.jpg';
import SigninForm from './SigninForm';


const loginvid = () => {
	return(<div className='loginpage'> 
	<div className='tc form_signin'><SigninForm/></div>
	<img className='myvid' src={Background} alt='Background' />
	<div className='overlay'></div>
		
		<div className='white pt0 z-3 tc vaguss_text'><NavLink className=' link white z-3' to="/">Vaguss</NavLink></div>
		<div className='slogan white hover-light-green'>Explore with community</div>
	
	
	 

	 </div>)
}
export default loginvid;

