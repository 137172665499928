import React from 'react';
import './Footer.css';


const Footer =()=>{
	return (<div className='footer'>
		<div className='sent'><p className='white'>By continuing through this page you had agreed all the terms & conditions of our privacy policy.</p></div>



	</div>)
}

export default Footer;